@if (isRootPage) {
  <ion-header class="root-header">
    <ion-toolbar class="home-header">
      @if (menuButton) {
        <ion-buttons slot="start" class="left-buttons">
          <ion-menu-toggle>
            <ion-button size="small">
              <ion-icon name="information-circle-outline" slot="icon-only" color="dark"></ion-icon>
            </ion-button>
          </ion-menu-toggle>
        </ion-buttons>
      }

      <ion-title routerLink="/" class="cursor-pointer">
        <img src="assets/images/rodzafer_logo.png" class="rodzafer-logo"/>
      </ion-title>
      <app-discret-progress></app-discret-progress>
      <ion-buttons slot="end">
        <ng-content select="toolbar-buttons"></ng-content>
      </ion-buttons>
      @if (loading) {
        <ion-progress-bar type="indeterminate" color="primary"></ion-progress-bar>
      }
    </ion-toolbar>
    <ng-content select="toolbar"></ng-content>

    <!-- decoration for webapp -->
    <img src="assets/images/oeil_gauche.webp" class="bg-image img1"> <img src="assets/images/oeil_droit.webp"
                                                                          class="bg-image img2">
  </ion-header>

} @else {
  <ion-header class="inner-page-header">
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <!-- we don't use ion-back-button to free catalog images & dom -->
        <ion-button (click)="backButton()">
          <ion-icon name="arrow-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>
        <b>{{ title }}</b>
        @if (subtitle) {
          <br/><small>{{ subtitle }}</small>
        }
        <div class="rodzafer-mini-logo ion-hide-sm-down" (click)="backButton()">
          <img src="assets/images/rodzafer_logo.png"/>
        </div>
      </ion-title>
      <ion-buttons slot="end">
        <ng-content select="toolbar-buttons"></ng-content>
      </ion-buttons>
      @if (loading) {
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      }
    </ion-toolbar>
  </ion-header>
}

<ion-content [class.home-content]="isHomePage" [class.inner-page]="!isRootPage" [scrollY]="scrollY">
  <div [id]="isHomePage ? 'page-container' : undefined" class="page-content">
    <ng-content></ng-content>
  </div>
</ion-content>
