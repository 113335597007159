import { Injectable } from '@angular/core';
import { Directory, Filesystem } from "@capacitor/filesystem";

@Injectable({
	providedIn: 'root'
})
export class FileCacheService {

	constructor() {
	}

	async fileCacheExists(path: string, filename: string): Promise<boolean> {
		try {
			await Filesystem.stat({
				path: path + '/' + filename,
				directory: Directory.Cache
			});
			return true;
		}
		catch (checkDirException: any) {
			return false;
		}
	}

	/**
	 * If file is not already in cache, we download it in cache
	 * Then we return the cached url
	 */
	async getFileCacheFromUrl(url: string, path: string, filename: string): Promise<string> {
		if (!await this.fileCacheExists(path, filename)) {
			await this.downloadFileToCache(url, path, filename);
		}
		return this.getFileCache(path, filename) as Promise<string>;
	}

	async downloadFileToCache(url: string, path: string, filename: string) {
		// create dir if not exists
		try {
			await Filesystem.mkdir({directory: Directory.Cache, path: path, recursive: true});
		}
		catch (e) {
			// directory already exists
		}

		const result = await Filesystem.downloadFile({
			url: url,
			path: path + '/' + filename,
			directory: Directory.Cache,
		});
		return result.path;
	}

	/**
	 * Return a cached file in the format it has been stored
	 * @param path
	 * @param filename
	 */
	async getFileCache(path: string, filename: string): Promise<string | null> {
		try {
			const result = await Filesystem.readFile({
				directory: Directory.Cache,
				path: path + '/' + filename
			});
			const file = result.data;
			if (file instanceof Blob) {
				// convert to base64$
				return URL.createObjectURL(file);
			}
			return `data:image/jpeg;base64,${file}` as string;
		}
		catch (e) {
			// file not exists
			return null;
		}
	}
}
