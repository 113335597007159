import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

/**
 * This service wrap observable request to promise request in order to write sequential-style code
 */
@Injectable({
	providedIn: 'root'
})
export class HttpService {

	constructor(private http: HttpClient) {
	}

	async get<T>(url: string): Promise<T> {
		return firstValueFrom(this.http.get<T>(url));
	}

	async getAll<T>(url: string): Promise<T[]> {
		return firstValueFrom(this.http.get<T[]>(url));
	}

}
