import { Injectable } from '@angular/core';
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { FirebaseJsService } from "./firebase-js.service";
import { Capacitor } from "@capacitor/core";
import { $next } from "../../utils/async.utils";
import { initializeApp } from "firebase/app";
import { environment } from "../../../environments/environment";
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";

export enum AnalyticsEvents {
  list_catalogs = "list_catalogs",
  view_catalog = "view_catalog",
  list_company = "list_company"
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private jsAnalytics: Analytics;

  constructor(private title: Title, private router: Router, private firebaseJS: FirebaseJsService) {
  }

  async initAnalytics() {
    if (Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.setCollectionEnabled({
        enabled: true,
      });
    }
    else {
      // web js
      const app = initializeApp(environment.firebaseWebApp.config)
      this.jsAnalytics = getAnalytics(app);
    }
  }

  async logPageView(event: { category?: string, itemId?: string, itemName?: string }) {
    await $next();
    const title = this.title.getTitle();
    const path = this.router.url;

    const itemParams: any = {};
    if (event.itemId) itemParams.item_id = event.itemId;
    if (event.itemId || event.itemName) itemParams.items =
      [{item_id: event.itemId, item_name: event.itemName}];

    if (Capacitor.isNativePlatform()) {
      // await FirebaseAnalytics.setScreenName({
      //   screenName: title,
      //   nameOverride: path
      // });
      await FirebaseAnalytics.logEvent({
        name: 'screen_view',
        params: {
          screen_name: title,
          screen_class: path,
          ...itemParams
        }
      })
    }
    else {
      // web log
      logEvent(this.jsAnalytics, 'page_view', {
        page_title: title,
        page_path: path,
        ...itemParams
      });
    }
  }
}
