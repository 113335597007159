export enum SmartFormats {
  WEB_FOOTER = "WEB_FOOTER",
  WEB_HABILLAGE = "WEB_HABILLAGE",
  WEB_INTERSTITIEL = "WEB_INTERSTITIEL",
  WEB_MBAN_ATF0 = "WEB_MBAN_ATF0",
  WEB_MBAN_ATF1 = "WEB_MBAN_ATF1",
  WEB_MBAN_ATF1A = "WEB_MBAN_ATF1A",
  WEB_MBAN_ATF2 = "WEB_MBAN_ATF2",
  WEB_MPAVE_ATF0 = "WEB_MPAVE_ATF0",
  WEB_MPAVE_ATF0A = "WEB_MPAVE_ATF0A",
  WEB_MPAVE_ATF1 = "WEB_MPAVE_ATF1",
  WEB_MPAVE_ATF1A = "WEB_MPAVE_ATF1A",
}

const SMART_MOBILE_BREAKPOINT = 992;
// we can use a counst because smart use screen detection at start in a non-dynamic way
export const isSmartMobileEnv = document.body.clientWidth < SMART_MOBILE_BREAKPOINT;
