import { Component, OnInit } from '@angular/core';
import { PageComponent } from "../../../components/page/page.component";

@Component({
  selector: 'app-conditions-art',
  templateUrl: './conditions-art.component.html',
  styleUrls: ['./conditions-art.component.scss'],
  imports: [
    PageComponent
  ],
  standalone: true
})
export class ConditionsArtComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
