import { Injectable } from '@angular/core';
import { Company } from "../../models/api/company.model";
import { Catalog } from "../../models/api/catalog.model";
import { CatalogPage } from "../../models/api/catalog-page.model";
import { environment } from "../../../environments/environment";
import { FileCacheService } from "./file-cache.service";
import { hideDiscretProgress, showDiscretProgress } from "../../loading.state";
import { CATALOG, CATALOGPAGE, COMPANY } from "../../dictionary";

const DOWNLOAD_IN_PARRALEL = 5;

export enum CachePaths {
  Company = COMPANY,
  Catalog = CATALOG,
  CatalogPage = CATALOGPAGE
}

@Injectable({
  providedIn: 'root'
})
export class PreloadImagesService {

  constructor(private fileCacheService: FileCacheService) {
  }

  async preloadImages(entities: (Company | Catalog | CatalogPage)[],
                      cachePath: CachePaths,
                      message: string): Promise<void> {

    // load image for already cached
    for (let entity of entities) {
      const {path, filename} = this.getPathAndFilename(entity, cachePath);
      entity.imageCache = await this.fileCacheService.getFileCache(path, filename);
    }
    // load for non cached image
    entities = entities.filter(c => !c.imageCache);

    // allow to display loading percent to user
    const showPercent = (p: number) => showDiscretProgress(p, message);
    for (let i = 0; i < entities.length; i += DOWNLOAD_IN_PARRALEL) {
      showPercent(Math.round(i / entities.length * 100));
      const companyPack = entities.slice(i, i + DOWNLOAD_IN_PARRALEL);
      // promise to preload image cache
      const preloadBatch =
        companyPack.map(entity => {
          const {path, filename} = this.getPathAndFilename(entity, cachePath);
          return this.preloadImage(entity, path, filename);
        });
      await Promise.all(preloadBatch);
    }
    hideDiscretProgress();
  }

  async preloadImage(entity: Company | Catalog | CatalogPage,
                     path: string, filename: string) {
    const url = environment.apiServer + entity.image;
    const file = await this.fileCacheService.getFileCacheFromUrl(url, path, filename) as string
    entity.imageCache = file
  }

  getPathAndFilename(entity: Company | Catalog | CatalogPage, cachePath: CachePaths): {
    path: string,
    filename: string
  } {
    const pathParts = (cachePath + entity.image).split('/');
    const filename = pathParts.pop() as string;
    const path = pathParts.join('/');
    return {path, filename}
  }
}
