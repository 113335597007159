import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Router } from "@angular/router";
import { routeToCatalogId } from "../../utils/route.utils";
import { FirebaseMessaging, GetTokenOptions, Notification } from "@capacitor-firebase/messaging";
import { environment } from "../../../environments/environment";
import { LocalNotifications } from "@capacitor/local-notifications";

export const PUSH_CATALOGID_KEY = 'catalogId';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {

  private _pushToken: string;
  get pushToken() {
    return this._pushToken;
  }

  constructor(private router: Router) {
    this.registerEvents();
  }

  public async requestPermissions(): Promise<void> {
    await FirebaseMessaging.requestPermissions();
  }

  async initPushNotifications() {
    // no web push for now
    if (!Capacitor.isNativePlatform()) return;

    await this.requestPermissions();
    await this.getToken();
  }

  public async getToken(): Promise<void> {
    const options: GetTokenOptions = {
      vapidKey: environment.firebaseWebApp.webPushKey,
    };
    if (Capacitor.getPlatform() === "web") {
      options.serviceWorkerRegistration =
        await navigator.serviceWorker.register("firebase-messaging-sw.js");
    }
    const {token} = await FirebaseMessaging.getToken(options);
    console.log("push token : " + token);
    this._pushToken = token;
  }

  private onNotificationOpen(data: { catalogId: string }) {
    const catalogId = data[PUSH_CATALOGID_KEY];
    if (catalogId) {
      console.debug("push navigate to " + catalogId);
      this.router.navigate([routeToCatalogId(catalogId)])
    }
  }

  private registerEvents() {
    // called when notification is received in the foreground
    FirebaseMessaging.addListener("notificationReceived", (event) => {
      console.log("notificationReceived: ", {event});
      this.createLocalNotification(event.notification);
    });
    // called when user click on a notification in the status bar
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("notificationActionPerformed: ", {event});
      this.onNotificationOpen(event.notification.data as any)
    });
    // called when user click on a local notification in the status bar
    LocalNotifications.addListener("localNotificationActionPerformed", (event) => {
      console.log("localNotificationActionPerformed: ", {event});
      this.onNotificationOpen(event.notification.extra as any)
    });
    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.addEventListener("message", (event: any) => {
        console.log("serviceWorker message: ", {event});
        const notification = new Notification(event.data.notification.title, {
          body: event.data.notification.body,
          data: event.data.data
        });
        notification.onclick = (event) => {
          console.log("web notification clicked: ", {event});
          this.onNotificationOpen(notification.data);
        };
      });
    }
  }

  private createLocalNotification(notification: Notification) {
    if (!Capacitor.isNativePlatform()) return;

    LocalNotifications.schedule({
      notifications: [
        {
          id: Math.round(Math.random() * 10000),
          title: notification.title,
          body: notification.body,
          extra: notification.data,
          smallIcon: 'ic_rodzafer_notif',
          iconColor: '#FFD204'
        }
      ]
    })
  }
}
