export const loadingState = {isLoading: false, message: ''};
const loadingQueue: { [key: string]: string } = {};


export function showLoading(code: string, message = '') {
	loadingQueue[code] = message;

	loadingState.message = Object.values(loadingQueue).join("\n");
	loadingState.isLoading = true;
}

export function hideLoading(code: string) {
	delete loadingQueue[code];
	if (!Object.keys(loadingQueue).length)
		loadingState.isLoading = false;
}

export const progressState = {show: false, progress: 0, message: ''};

export function showDiscretProgress(progress: number, message: string) {
	progressState.show = true;
	progressState.progress = progress;
	progressState.message = message;
}

export function hideDiscretProgress() {
	progressState.show = false;
}
