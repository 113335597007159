/**
 * function which returns an empty promise which resolve after x ms
 * @param ms
 */
export function $wait(ms: number): Promise<void> {
	return new Promise<void>(resolve => {
		setTimeout(() => resolve(), ms)
	});
}

/**
 * A promise which just wait for next JS loop
 */
export function $next(): Promise<void> {
	return $wait(0);
}
