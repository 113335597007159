export const CATALOG = 'catalog';
export const CATEGORY = 'category';
export const COMPANY = 'company';
export const CATALOGPAGE = 'catalogPage';
export const CITY = 'city';

export const HumanPath = {
	catalogs: 'catalogues',
	catalog: 'catalogue',
	categories: 'categories',
	category: 'categorie',
	companies: 'enseignes',
	company: 'enseigne',
	myCompanies: 'mesEnseignes'
};
export type HumanPath = keyof typeof HumanPath;
export const ApiPath = {
	catalog: {single: 'catalog', plurial: 'catalogs'},
	catalogPage: {single: 'page', plurial: 'pages'},
	category: {single: 'category', plurial: 'categories'},
	company: {single: 'company', plurial: 'companies'},
};

