import { Component } from '@angular/core';
import { IonApp, IonLoading, IonRouterOutlet } from '@ionic/angular/standalone';
import { PushNotificationsService } from './services/utils/push-notifications.service';
import { DeepLinkService } from './services/utils/deep-link.service';
import { register } from 'swiper/element/bundle';
import { Settings } from 'luxon';
import { IconsService } from './services/utils/icons.service';
import { loadingState } from "./loading.state";
import { UserParamsService } from "./services/utils/user-params.service";
import { BackButtonService } from "./services/utils/back-button.service";
import { SideMenuComponent } from "./components/side-menu/side-menu.component";
import { SmartTagComponent } from "./components/smart-tag/smart-tag.component";
import { SmartFormats } from "../smart.config";
import { AnalyticsService } from "./services/utils/analytics.service";
import { StatusBar } from "@capacitor/status-bar";
import { $wait } from "./utils/async.utils";
import { Capacitor } from "@capacitor/core";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, IonLoading, SideMenuComponent, SmartTagComponent],
  providers: [PushNotificationsService],
})
export class AppComponent {

  loadingState = loadingState;
  ready = false;
  protected readonly SmartFormats = SmartFormats;

  constructor(private pushService: PushNotificationsService,
              private analytics: AnalyticsService,
              private deepLinkService: DeepLinkService, private iconsService: IconsService,
              private backButtonService: BackButtonService,
              private userParamsService: UserParamsService) {
    this.init();
  }

  async init() {
    this.clearServiceWorkerCache();

    const loadingCode = "INIT";
    //showLoading(loadingCode, "Initialisation");

    this.initStatusBar();
    this.analytics.initAnalytics();
    this.deepLinkService.initDeepLinks();
    this.iconsService.initIcons();
    this.backButtonService.initBackButton();

    try {
      await this.initAsyncParams();
    }
    catch (e) {
      console.debug("error loading catalogs");
      console.debug(e);
    }
    // default date locale
    Settings.defaultLocale = 'fr';

    this.ready = true;
    await $wait(500);

    this.pushService.initPushNotifications();
  }

  private clearServiceWorkerCache() {
    caches?.keys().then(function (names) {
      for (let name of names)
        caches.delete(name);
    });
  }

  async initAsyncParams() {
    await Promise.all([
      this.userParamsService.initUserParams(),
    ]);
  }

  private initStatusBar() {
    // for Android only
    if (Capacitor.isNativePlatform())
      StatusBar.setBackgroundColor({color: 'FFD204'})
  }
}
