import { Injectable } from '@angular/core';
import { FirebaseApp, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FirebaseJsService {

  constructor() {
  }

  private _app: FirebaseApp;
  private _analytics: any;

  init() {
    if (!this._app) {
      this._app = initializeApp(environment.firebaseWebApp.config);
    }
  }

  get app(): FirebaseApp {
    this.init();
    return this._app;
  }

  get analytics() {
    if (!this._analytics) {
      this._analytics = getAnalytics(this.app);
    }
    return this._analytics;
  }
}
