<app-page>

  <pre style="white-space: break-spaces" class="ion-padding">

    <h1>Charte d’utilisation des données de l’application mobile : RODZAFER</h1>
L’application mobile « RODZAFER » est éditée par la société Antenne Réunion Télévision.
Le groupe Antenne Réunion et ses filiales, s’engagent à respecter votre vie privée ainsi que les
lois applicables sur la protection des données, conformément à la loi française "Informatique et
Libertés" n° 78-17 du 6 janvier 1978 modifiée par le Règlement Européen sur les Données
Personnelles (RGPD).

La présente Charte d’utilisation des données de l’application mobile : RODZAFER a pour
objet de compléter la Charte d’utilisation des données du groupe ANTENNE REUNION et de
répondre aux spécificités de collecte et d'utilisation des données personnelles lors de votre
utilisation de l’application mobile « RODZAFER ».

I. Comment est déterminée votre positionnement par rapport au magasin présent dans
l’application RODZAFER ?

a. Fonction : Saisissez votre code postal

Lors de l’installation de l’application RODZAFER, vous êtes invités à indiquer votre code
postal. Cette information déclarative est utilisée pour déterminer un point GPS permettant
d’estimer à quelle distance vous vous trouvez des magasins et enseignes proposant des
catalogues sur l’application RODZAFER.

b. Fonction : Chercher autour de « Ma position actuelle »

La fonction « chercher autour de la position actuelle » permet d’activer le système de
géolocalisation. Grâce à cette fonctionnalité, l’application pourra déterminer plus
précisément à quelle distance vous vous trouvez des magasins et enseignes proposant des
catalogues sur l’application RODZAFER.
Les données et coordonnées GPS ne sont pas conservées par l’application RODZAFER.
Seule la dernière position est sauvegardée pour établir les estimations de distance.

II. Pour quelle raison l’application RODZAFER utilise des cookies, traceurs et
identifiants publicitaires ?

a. Les cookies nécessaires au fonctionnement de l’application RODZAFER

Ces Cookies et autres traceurs sont utilisés à des fins analytiques, et pour la bonne
utilisation des services offerts par l’application RODZAFER, notamment aux fins
suivantes :
- Navigation et usage de l’applications RODZAFER ;
- Performance et fonctionnalité ;
- Mesures d’audience et statistique.
Les cookies déposés sur le téléphone du mobinaute ont une durée de vie de 13 mois.

b. Les cookies publicitaires, traceurs et identifiants publicitaires

Les cookies, traceurs et identifiants publicitaires exploitent des informations sur votre
utilisation de l’application RODZAFER, dans le but de vous proposer des publicités
susceptibles de vous intéresser davantage.
Les cookies déposés sur le téléphone du mobinaute ont une durée de vie de 13 mois. A
l’issue de cette période, le consentement du mobinaute sera à nouveau sollicité pour la
dépose

III. Comment contacter la CNIL ?

En cas de question, difficulté ou pour émettre une réclamation, il vous est possible de prendre
contact auprès de la CNIL à l’adresse suivante : 3 Place de Fontenoy - TSA 80715 - 75334
PARIS CEDEX 07 Tél : 01 53 73 22 22

  </pre>

</app-page>
