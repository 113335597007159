import { Injectable } from '@angular/core';

/**
 * A simple cache system living only in the session of the app (once per app launch)
 */
@Injectable({
	providedIn: 'root'
})
export class SessionCacheService {

	private cache: { [key: string]: any } = {};

	constructor() {
	}

	setCache(key: string, value: any) {
		this.cache[key] = value;
	}

	getCache(key: string) {
		return this.cache[key];
	}
}
