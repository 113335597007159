import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

/**
 * A simple wrapper for Ionic storage to abstractize storage access
 */
@Injectable({
	providedIn: 'root'
})
export class StorageService {
	private storage: Storage;

	constructor(private storageEngine: Storage) {
	}

	async initStorage() {
		// already initialized ?
		if (this.storage) return;

		// If using, define drivers here: await this.storage.defineDriver(/*...*/);
		this.storage = await this.storageEngine.create();
		// activate encryption with storage.setEncryptionKey('mykey'); https://github.com/ionic-team/ionic-storage#encryption-support
	}

	public set<T>(key: string, value: T) {
		return this.storage.set(key, value);
	}

	public get<T>(key: string): Promise<T> {
		return this.storage.get(key);
	}

	public remove(key: string) {
		return this.storage.remove(key);
	}
}
