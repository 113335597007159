<app-page>

  <pre style="white-space: break-spaces" class="ion-padding">

    <h1>Charte d’utilisation des données du groupe ANTENNE REUNION</h1>

Antenne Réunion Télévision, y compris ses filiales, s’engagent à respecter votre vie privée ainsi
que les lois applicables sur la protection des données, conformément à la loi française
"Informatique et Libertés" n° 78-17 du 6 janvier 1978 modifiée par le Règlement Européen sur
les Données Personnelles (RGPD).

La présente Charte d’utilisation des données a pour objet de décrire nos méthodes de collecte
et d'utilisation des données personnelles lors de votre navigation sur l’un des sites internet du
groupe Antenne Réunion ou lors de l’utilisation de nos services et/ou applications (mobile et
tablette) dès lors qu’Antenne Réunion Télévision ou l’une de ses filiales sont responsables du
traitement.

Ainsi, cette Charte est destinée à vous informer de vos droits et de nos obligations en matière
de protection de la vie privée et des données personnelles.

Définitions :
Cookies : Lors de vos visites sur l’un des Sites Internet, des cookies peuvent être installés
automatiquement (avec consentement) sur votre logiciel de navigation par le Groupe
ANTENNE REUNION, l’un de ses Sous-Traitants ou l’une de ses sociétés Partenaires. Le cookie
est un fichier qui ne permet pas d’identifier les utilisateurs (sauf si accord) mais sert à
enregistrer des informations relatives à la navigation de celui-ci sur le site notamment des
statistiques d’audiences, historique de navigation. Les informations contenues dans ce fichier
ne pourront être analysées ou modifiées que par la société émettrice du Cookie. Ils
permettent de suggérer du contenu, des annonces publicitaires ciblées etc.
Donnée personnelle : Constitue une donnée personnelle toute information relative à une
personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par
référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres.
Donnée de navigation : désigne les données qui se rapportent à la connexion d’un terminal à
un service de communication électronique à un instant donné.

Groupe ANTENNE REUNION : désigne la société Antenne Réunion Télévision, ainsi que ses
filiales : Antenne Réunion Publicité et COREDEV.
Partenaire : désigne toute société qui aura conclu un partenariat commercial avec l’une des
sociétés du groupe ANTENNE REUNION.
Utilisateurs : désignent toute personne physique qui accède aux Sites Internet ou utilise l’un
des services et/ou applications (mobile et tablette) du Groupe ANTENNE REUNION.
Sites Internet : désignent les sites suivants : www.linfo.re, www.antennereunion.fr,
www.boutikantenne.fr, www.immo974.com, www.domtomjob.com,
www.domtomauto.com.

Sous-Traitant : désigne toute société qui peut être amenée à avoir accès et traiter des
Données Personnelles ou des Données de Navigation, pour le compte du Groupe ANTENNE
REUNION conformément aux instructions données par celui-ci.

I. Quel est le cadre législatif ?

Le Groupe ANTENNE REUNION respectent les normes européennes et françaises en matière
de protection des données personnelles. Les droits et garanties de nos Utilisateurs régis par
notre Charte d’utilisation des données sont soumis à la loi française « Informatique et Libertés
» n° 78-17 du 6 janvier 1978 modifiée, la loi « pour la confiance dans l'économie numérique »
(« LCEN ») n° 2004-575 du 21 juin 2004 (article L. 33-4-1 du Code des postes et
télécommunications et article L. 121-20-5 du Code de la consommation) et par le règlement
général n°2016/679 sur la protection des données.

II. Quelles sont les données et informations recueillies ?

Nous pouvons être amenés à recueillir des Données Personnelles lorsque l’Utilisateur s’inscrit
notamment, à l’une de nos newsletters, ou à l'un des services proposés sur nos Sites Internet,
lorsque l’Utilisateur effectue un achat, participe à un jeu, un concours ou répond à un sondage
et sur la base de notre intérêt légitime.
➢ Données Personnelles collectées via des Cookies (et technologies similaires) :
o Consultation et navigation sur nos sites o Fréquentation des pages visitées o
Mesure d’audience sur le terminal
o Contenus consultés (nom du programme, durée du visionnage…)
o Adresse IP du terminal connecté aux services o Zone de géolocalisation
o Date, heure et durée de connexion d’un terminal à un des services o Adresse
Internet de la page de provenance du terminal accédant aux services o
Personnalisation sur le terminal o Type de système d’exploitation du terminal
o Type et version du logiciel de navigation utilisé par le terminal
o Possibles erreurs de téléchargement
o Langue d’utilisation du logiciel de navigation utilisée par le terminal o Réglages
du lecteur multimédia (volume sonore choisi lors de votre navigation, l’endroit
où vous vous êtes arrêtés)
o Informations sur les transaction effectuées ou initiées (produits concernés,
type de transactions…) o Identifiants publicitaires mobiles
o Sessions des utilisateurs
o Activation de JavaScript dans le navigateur utilisé

    ➢ Données Personnelles que vous consentez à nous communiquer directement via un
formulaire ou lors de l’inscription l’un de nos services :
o Civilité, nom, prénom, âge, adresse, pays, sexe, email, date de naissance,
téléphone (fixe et mobile), professionnel ou particulier, profession, centres
d’intérêt, photo de profil, locataire ou propriétaire.

III. Quelles utilisations fait-on de vos données ?

o Gestion des clients :
- les contrats ; les commandes ; les livraisons ; les factures ; la
comptabilité et en particulier la gestion des comptes clients ; un
programme de fidélité au sein d'une entité ou plusieurs entités
juridiques ; le suivi de la relation client tel que la réalisation d'enquêtes
de satisfaction, la gestion des réclamations et du service après-vente ;
la sélection de clients pour réaliser des études, sondages et tests
produits (sauf consentement des personnes concernées recueilli dans
les conditions adéquates et légales ces opérations ne doivent pas
conduire à l'établissement de profils susceptibles de faire apparaître
des données sensibles - origines raciales ou ethniques, opinions
philosophiques, politiques, syndicales, religieuses, vie sexuelle ou santé
des personnes).
 O Prospection
- la gestion d’opérations techniques de prospection (ce qui inclut
notamment les opérations techniques comme la normalisation,
l'enrichissement et la déduplication) ;
- la sélection de personnes pour réaliser des actions de fidélisation, de
prospection, de sondage, de test produit et de promotion. Sauf
consentement des personnes concernées recueilli dans les conditions
adéquates et légales, ces opérations ne
conduiront pas à l'établissement de profils susceptibles de
faire apparaître des données sensibles (origines raciales ou ethniques,
opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle
ou santé des personnes)
;
- la réalisation d'opérations de sollicitations ;
o Opérations de fidélisation o Elaboration de statistiques commerciales o
Analyses statistiques o Cession, location ou échange de fichiers de clients et de
prospects o Organisation de jeux concours, de loteries ou de toute opération
promotionnelle à l'exclusion des jeux d'argent et de hasard en ligne soumis à
l'agrément de l'Autorité de Régulation des Jeux en Ligne ;

    o Gestion des demandes de droit d’accès, de rectification, d’opposition,
effacement et portabilité
o Profilage o Gestion des avis des personnes sur des produits, services ou
contenus o Statistique de mesure d’audience ; o Personnalisation des services
;
o Statistiques permettant la vente d’espace publicitaire par marketing
programmatique ;
o Constitution d’une base de données clients à des fins de prospection
commerciale
o Newsletter de nature informationnelle
o Gestion des impayés et du contentieux, à condition qu'elle ne porte pas sur des
infractions et/ou qu'elle n'entraine pas une exclusion de la personne du
bénéfice d'un droit, d'une prestation ou d'un contrat ;

IV. Pendant combien de temps vos données sont-elles conservées ?

➢ Concernant les données relatives à la gestion des clients et prospects :
Les données à caractère personnel relatives aux clients ne pourront être conservées au-delà
de la durée strictement nécessaire à la gestion de la relation commerciale.
Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, ou
conservées au titre du respect d’une obligation légale, pourront faire l’objet d’une politique
d’archivage intermédiaire pour une durée n’excédant pas la durée nécessaire aux finalités
pour lesquelles elles sont conservées, conformément aux dispositions en vigueur (notamment
mais non exclusivement celles prévues par le code de commerce, le code civil et le code de la
consommation). Il sera prévu à cet effet une base de données d’archives dédiée ou une
séparation logique dans la base de données active, après avoir opéré un tri des données
pertinentes à archiver.
Les données à caractère personnel relatives à un Utilisateur seront conservées pendant un
délai de trois ans à compter de leur collecte par le Groupe ANTENNE REUNION ou du dernier
contact émanant du prospect (par exemple, une demande de documentation ou un clic sur un
lien hypertexte contenu dans un courriel ; en revanche, l'ouverture d’un courriel ne peut être
considérée comme un contact émanant du prospect).
Au terme de ce délai de trois ans, le Groupe ANTENNE REUNION pourra reprendre contact
avec la personne concernée afin de savoir si elle souhaite continuer à recevoir des
sollicitations commerciales. En l’absence de réponse positive et explicite de la personne, les
données seront supprimées ou archivées conformément aux dispositions en vigueur, et
notamment celles prévues par le Code de commerce, le Code civil et le Code de la
consommation.
➢ Concernant les pièces d’identité :
En cas d'exercice du droit d'accès ou de rectification, les données relatives aux pièces
d'identité pourront être conservées pendant le délai prévu à l'article 9 du Code de procédure
pénale (soit un an). En cas d'exercice du droit d'opposition, ces données pourront être
archivées pendant le délai de prescription prévu à l'article 8 du Code de procédure pénale (soit
trois ans).
➢ Concernant la gestion des listes d'opposition à recevoir de la prospection :
Lorsqu'une personne exerce son droit d'opposition à recevoir de la prospection auprès du
Group ANTENNE REUNION, les informations permettant de prendre en compte son droit
d'opposition seront conservées au minimum trois ans à compter de l'exercice du droit
d'opposition. Ces données ne seront en aucun cas utilisées à d'autres fins que la gestion du
droit d'opposition et seules les données nécessaires à la prise en compte du droit d’opposition
seront conservées (par exemple, l’adresse électronique).
➢ Concernant les Données de navigation :
Les informations stockées dans le terminal des Utilisateurs (ex : cookies), ou tout autre
élément utilisé pour identifier les Utilisateurs et permettant leur traçabilité, ne seront pas
conservés au-delà de treize mois. Les nouvelles visites ne prolongera pas la durée de vie de
ces informations. Les données de fréquentation brutes associant un identifiant ne seront
pas conservées plus de treize mois. Au-delà de ce délai, les données seront soit supprimées,
soit anonymisées.

V. Qui peut exploiter les données ?

➢ Le Groupe ANTENNE REUNION :
Les sociétés du groupe ANTENNE REUNION : Antenne Réunion Télévision, Antenne Réunion
publicité et COREDEV.
Au sein des sociétés du Groupe ANTENNE REUNION, seul le personnel habilité a accès aux
Données Personnelles et aux Données de navigation, uniquement dans le cadre de leur
mission et ils sont soumis à une stricte obligation de confidentialité.
➢ Sous-Traitants :
Les Sous-Traitants, auxquels le Groupe ANTENNE REUNION fait appel en matière de
prestations techniques, fournisseurs de solutions analytiques, prestation de
jeuxconcours/sondage, sollicitation commerciale, envoi de newsletter etc. ;
Vous trouverez ci-dessous la liste des Sous-traitants susceptibles d’accéder à des Données
personnelles et des Données de navigation, ainsi que leur lieu de résidence et leur mail de
contact aux fins de pouvoir exercer vos droits auprès de ces entités :
• Sous-traitants Antenne Réunion Télévision :
▪ Digital Virgo (France) : service-rgpd&#64;digitalvirgo.com
▪ Mobiyo (France) : contact&#64;hipay.com
▪ Smart (France) : privacy&#64;smartadserver.com
▪ Mailchimp (Etats-Unis) : dpo&#64;mailchimp.com
▪ Criteo (Etats-Unis) : dpo&#64;criteo.com
▪ Rubicon (Etats-Unis) : privacy&#64;rubiconproject.com
Page 6 sur 8
▪ Yldlove (Allemagne) : contact&#64;yieldlove.com
• Sous-traitants Antenne Réunion Publicité :
▪ Smart AdServer SAS (Paris): contact&#64;smartadserver.com
▪ Nugg.ad (Allemagne) : datenschutz&#64;nugg.ad
• Sous-traitants Boutik Antenne :
▪ Adelya (France) : contact&#64;adelya.com
▪ Octave (France) : info&#64;octave.biz
▪ Lyra (France) : dpo&#64;lyra.dental
➢ Tiers autorisés par la loi
Des tiers dans la limite des cas encadrés par la loi, les autorités judiciaires ou administratives
par exemple.

VI. Comment consentez-vous aux offres de nos partenaires ?

Vous avez demandé à recevoir par e-mail et/ou par SMS et/ou courrier postal des offres
portant sur des produits ou des services fournis par nos Partenaires, en cochant la case prévue,
notamment en vous souscrivant à nos Newsletters et les services proposés par nos Sites
Internet.
Vous avez donc accepté que nos Partenaires disposent de vos données personnelles et
puissent vous adresser leurs offres, jusqu’au retrait de votre consentement.
Le Groupe ANTENNE REUNION n’est pas responsable du traitement de vos Données
Personnelles par nos Partenaires, une fois que celles-ci leur ont été transmises conformément
à votre demande. Nos Partenaires sont responsables à votre égard, de l'usage qu'ils font de
vos Données Personnelles et du respect de vos droits, notamment de rectification, correction,
suppression, effacement et portabilité de ces données.
VII. Quels sont vos droits en tant qu’Utilisateur ?
Vous disposez de différents droits concernant le traitement de vos Données personnelles : un
droit d’accès, d’interrogation et de rectification, un droit à l’oubli, un droit à l’opposition, un
droit à la portabilité, un droit de ne pas faire l’objet d’une décision résultant exclusivement
d’un traitement automatisé.

➢ Droit d’accès, d’interrogation, rectification et opposition.
Dans les limites de la règlementation applicable, vous pouvez demander à rectifier, compléter,
mettre à jour, vous opposer et effacer les Données Personnelles vous concernant en nous
contactant aux adresses indiquées à la fin de la Charte.

➢ Droit à l’oubli.
Vous disposez d’un droit à l’effacement de vos Données Personnelles dans différents cas,
notamment lorsque :
- Le traitement est non nécessaire au regard des finalités pour lesquelles
les Données Personnelles ont été collectées ou traitées ;
- Le traitement est illicite
- Vous avez retiré votre consentement pour ce traitement de Données
Personnelles et il n’existe pas d’autre fondement juridique au
traitement ;
- Vous vous êtes opposé à ce traitement et il n’existe aucun motif légitime
impérieux justifiant ce traitement ;
- Il existe une obligation légale, prévue par la législation de l’Union ou des
Etats membres, à laquelle le Groupe ANTENNE REUNION est soumis,
concernant l’effacement de ces Données Personnelles.
En application des dispositions du décret n°2007-451 du 25 mars 2007 portant modification
du décret n°2005-1309 du 20 octobre 2005 pris pour l’application de la loi n°78-17 du 6 janvier
1978 relative à l’informatique, aux fichiers et aux libertés, votre demande d’exercice de l’un
de vos droits devra être accompagnée de la photocopie d’un titre d’identité portant la
signature du titulaire. Votre demande devra également préciser l’adresse mail à laquelle la
réponse devra vous parvenir.
Le Groupe ANTENNE REUNION vous répondra dans un délai d’un mois suivant la réception de
la demande. Ce délai peut être prolongé de deux mois compte tenu de la complexité et du
nombre de demandes.

➢ Droit à la portabilité
Vous disposez du droit à la portabilité de vos Données Personnelles, c’est-à-dire de recevoir
les Données Personnelles que vous nous avez fournies, dans un format structuré, couramment
utilisé et lisible par machine et de les transmettre à un autre responsable de traitement. Pour
cela contacter nous aux adresses indiquées à la fin de la Charte.

VIII. Qui contacter ?
Pour exercer l’ensemble de ces droits vous pouvez écrire à :
- Pour le site et service édité par Antenne Réunion Télévision : Antenne Réunion - BP
8000 -Saint-Denis CEDEX. contact&#64;antennereunion.fr
- Pour le site et service édité par immo974 : Antenne Réunion - BP 8000 -Saint-Denis
CEDEX. contact&#64;immo974.com
- Pour le site et service édité par domtomjob : Antenne Réunion - BP 8000 -Saint-Denis
CEDEX. contact&#64;domtomjob.com
- Pour le site et service édité par BOUTIK ANTENNE/COREDEV : 3, RUE EMILE HUGOT, ZI
n°2
97490 SAINTE CLOTILDE. boutik&#64;antennereunion.fr

IX. Comment contacter la CNIL ?

En cas de question, difficulté ou pour émettre une réclamation, il vous est possible de prendre
contact auprès de la CNIL à l’adresse suivante : 3 Place de Fontenoy - TSA 80715 - 75334 PARIS
CEDEX 07 Tél : 01 53 73 22 22

  </pre>
</app-page>
