import { inject } from '@angular/core';
import { HttpService } from '../utils/http.service';
import { SortOrder } from 'src/app/models/sortOrder.type';
import { PageResult } from 'src/app/models/api/page-result.model';
import { environment } from 'src/environments/environment';
import { SessionCacheService } from "../utils/session-cache.service";

const DEFAULT_NB_PER_PAGE = 100;

export abstract class ApiService<PART_TYPE, FULL_TYPE> {
  abstract listPrefix: string;
  abstract singlePrefix: string;

  protected http: HttpService = inject(HttpService);
  protected sessionCache: SessionCacheService = inject(SessionCacheService);

  get apiListUrl() {
    return environment.apiUrl + this.listPrefix;
  }

  /**
   * Return all entities of a type from the API
   * @param sortBy name of the field to sort by
   * @param nb nb
   * @param page
   * @returns
   */
  async getAll(sortBy?: string, sortOrder?: SortOrder, nb?: number,
               page: number = 1): Promise<PageResult<PART_TYPE>> {

    // TODO: remove sort filter or cache it
    const defaultSort = this.sortOrderDefault();
    if (!sortBy) sortBy = defaultSort.sortBy;
    if (!sortOrder) sortOrder = defaultSort.sortOrder;
    if (!nb) nb = defaultSort.nbPerPage || DEFAULT_NB_PER_PAGE;

    const params = [];
    // for DESC sort, we need to add "-" sign
    if (sortOrder == 'DESC') sortBy = '-' + sortBy;
    if (sortBy) params.push('s=' + sortBy);
    if (nb) params.push('n=' + nb);
    if (page) {
      // page is zero indexed
      page--;
      params.push('p=' + page);
    }
    const url = this.apiListUrl + '?' + params.join('&');

    // store request result in session (will be reload on app restart)
    let cache: PageResult<PART_TYPE> = this.sessionCache.getCache(url);
    if (!cache) {
      const result: PageResult<PART_TYPE> = await this.http.get<PageResult<PART_TYPE>>(url);
      result.hits = result.hits.filter((e) => this.isValid(e));
      await this.handleEntities(result.hits);

      this.sessionCache.setCache(url, result);
      cache = result;
    }
    return cache;
  }

  getOne(id: string) {
    const url = environment.apiUrl + this.singlePrefix + '/' + id;
    return this.http.get<FULL_TYPE>(url);
  }

  /**
   * Test an entity of the service to check if its valid.
   * If method returns false, element won't be included in getAll results
   */
  protected isValid(entity: PART_TYPE): boolean {
    return true;
  }

  /**
   * Allow to define default sortOrder for getAll method
   */
  protected abstract sortOrderDefault(): {
    sortBy: string; sortOrder: SortOrder, nbPerPage?: number
  };

  /**
   * Allow to handle entities after getAll and before caching
   */
  protected async handleEntities(e: PART_TYPE[]) {
    return e;
  }
}
