import { Component, HostListener, Input } from '@angular/core';
import { IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonFab, IonHeader, IonIcon, IonMenuButton, IonMenuToggle, IonProgressBar, IonTitle, IonToolbar, NavController, } from '@ionic/angular/standalone';
import { DiscretProgressComponent } from "../discret-progress/discret-progress.component";
import { Location } from "@angular/common";
import { SideMenuComponent } from "../side-menu/side-menu.component";
import { RouterLink } from "@angular/router";

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  standalone: true,
  imports: [
    IonAvatar,
    IonProgressBar,
    IonIcon,
    IonButton,
    IonFab,
    IonButtons,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    DiscretProgressComponent,
    IonMenuButton,
    SideMenuComponent,
    IonMenuToggle,
    RouterLink
  ],
})
export class PageComponent {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  isRootPage: boolean = false;
  @Input()
  scrollY: boolean = true;
  @Input()
  loading: boolean = false;

  /**
   * Url to an image displayed left to the page title
   */
  @Input()
  avatar: string;

  @Input()
  menuButton: boolean = false;

  @Input()
  isHomePage: boolean = false;

  constructor(private navCtrl: NavController, private location: Location) {
  }

  backButton() {
    // it wasn't the first page of the navigation
    // if (isRefererSameDomain())
    //   this.navCtrl.back({animated: true});
    // // it is the first page of the navigation
    // else
    this.navCtrl.navigateRoot('/', {animated: true});
  }

  @HostListener('window:keydown.Escape', ['$event'])
  escapeKeydown(event: KeyboardEvent) {
    if (!this.isRootPage) {
      event.preventDefault();
      this.backButton();
    }
  }
}
