import { Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

/**
 * deeplink are link from an url of the app
 * We assume that routing is the same than angular app routing
 * https://capacitorjs.com/docs/guides/deep-links
 */
@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {
  constructor(private router: Router, private zone: NgZone) {}

  initDeepLinks() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://rodzafer.re/catalogue/234234234
        let urlParts = event.url
          // remove protocol
          .replace(/(^\w+:|^)\/\//, '')
          .split('/');
        // remove domain
        urlParts.splice(0,1);
        const path = urlParts.join('/');
        if (path)
          this.router.navigateByUrl(path);
      });
    });
  }
}
