import { bootstrapApplication } from '@angular/platform-browser';
import { PreloadAllModules, provideRouter, withPreloading, } from '@angular/router';
import { provideIonicAngular, } from '@ionic/angular/standalone';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { provideHttpClient } from '@angular/common/http';
import { importProvidersFrom, isDevMode } from "@angular/core";
import { IonicStorageModule } from "@ionic/storage-angular";

// tell capacitor-updater that bundle is loaded
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { provideServiceWorker } from '@angular/service-worker';

CapacitorUpdater.notifyAppReady();

bootstrapApplication(AppComponent, {
  providers: [
    // we don't use IonicRouteStrategy as we don't want to reload component which are on same route declaration
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // use android rendering for base UI to provide consistent development process
    provideIonicAngular({mode: 'md'}),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    provideHttpClient(),
    // here is the place for external module loading (as we are in standalone mode)
    importProvidersFrom(IonicStorageModule.forRoot()), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          })
  ],
});
