import { Component, OnInit } from '@angular/core';
import { PageComponent } from "../../../components/page/page.component";

@Component({
  selector: 'app-conditions-rodzafer',
  templateUrl: './conditions-rodzafer.component.html',
  styleUrls: ['./conditions-rodzafer.component.scss'],
  imports: [
    PageComponent
  ],
  standalone: true
})
export class ConditionsRodzaferComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
