import { inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Catalog, CatalogFull, CatalogPart, } from 'src/app/models/api/catalog.model';
import { StateName } from 'src/app/models/api/state.model';
import { ApiPath } from 'src/app/dictionary';
import { SortOrder } from 'src/app/models/sortOrder.type';
import { CachePaths, PreloadImagesService } from "../utils/preload-images.service";

@Injectable({
  providedIn: 'root',
})
export class CatalogService extends ApiService<CatalogPart, CatalogFull> {
  override listPrefix: string = ApiPath.catalog.plurial;
  override singlePrefix: string = ApiPath.catalog.single;

  protected preloadImageService = inject(PreloadImagesService);

  override isValid(entity: CatalogPart): boolean {
    return entity.state.name == StateName.VALID;
  }

  override sortOrderDefault(): { sortBy: string; sortOrder: SortOrder } {
    return {sortBy: 'dateStart', sortOrder: 'DESC'};
  }

  async preloadImages(catalogs: Catalog[]): Promise<void> {
    await this.preloadImageService.preloadImages(catalogs, CachePaths.Catalog, 'Téléchargement des images de couverture');
  }
}
