import { Component } from '@angular/core';
import { PageComponent } from "../../../components/page/page.component";

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.scss'],
  imports: [
    PageComponent
  ],
  standalone: true
})
export class MentionsLegalesComponent {

  constructor() {
  }

}
