<app-page>
<pre style="white-space: break-spaces" class="ion-padding">
  <h1>Mentions légales : RODZAFER</h1>

  Société éditrice :
  Antenne Réunion Télévision
  SA au capital de 1 760 000 €
  Immatriculée RCS Saint Denis : 381 314 368
  3, rue Emile Hugot – 97490 Sainte Clotilde
  Directeur de publication : Mr Sylvain Péguillan - Directeur Général de la société
  Antenne Réunion Télévision
  Contact
  contact&#64;antennereunion.fr
  Téléphone
  02.62.48.28.28

  Propriété intellectuelle

  La structure générale ainsi que les logiciels, textes, images animées ou non, contenu sonore ou
  multimédia son savoir-faire et tous les autres éléments composant l’application RODZAFER sont la
  propriété exclusive de la société Antenne Réunion Télévision.

  Toute représentation totale ou partielle de ce site par quelle que personne que ce soit, sans
  l’autorisation expresse d’Antenne Réunion Télévision est interdite et constituerait une contrefaçon
  sanctionnée par les articles L. 335-2 et suivants du Code de la propriété intellectuelle.
  Les marques d’Antenne Réunion Télévision et de ses partenaires, ainsi que les logos figurant dans
  l’application sont des marques déposées.

  Toute reproduction totale ou partielle de ces marques ou de ces logos effectués à partir des éléments
  du site sans l’autorisation expresse d’Antenne Réunion Télévision est donc prohibée au sens du Code
  de la propriété intellectuelle.

  Antenne Réunion Télévision ne saurait être responsable de l’accès par les utilisateurs via les liens
  hypertextes mis en place dans le cadre de l’application en direction d’autres ressources présentes sur
  le réseau.
</pre>

</app-page>
