export const environment = {
	production: false,
	siteUrl: 'https://dev.rodzafer.re',

	activeAds: true,

	apiServer: 'https://api.rodzafer.re/',
	apiUrl: 'https://api.rodzafer.re/api/',
	apiVersion: '1.4.2',

	// used to reverse city name from coordoniates
	locationIQkey: 'pk.ae9f17ce5550f0646f269c6faf2dd4d8',

	// used for webapp messaging and tracking
	firebaseWebApp: {
		config: {
			apiKey: "AIzaSyC1z8NQC7RLlX4pD0wV-l1zkhDcmKfHEmg",
			authDomain: "hermes-226009.firebaseapp.com",
			databaseURL: "https://hermes-226009.firebaseio.com",
			projectId: "hermes-226009",
			storageBucket: "hermes-226009.appspot.com",
			messagingSenderId: "576777112527",
			appId: "1:576777112527:web:ddb376358a0c72570b60ba",
			measurementId: "G-XZT3GBE58Z"
		},
		webPushKey: 'BJP7fgS9iyLSSZ1fR9w2QarQZ7j0l8SMvPlqXNTBM8xoYnbzEMYEIz7CgzhxsNZu-Y13x3kIw-3VsH3JG7fduZY'
	},

};
