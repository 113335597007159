export enum StateName {
  'ARCHIVED' = 'ARCHIVED',
  'VALID' = 'VALID',
  'INPROGRESS' = 'INPROGRESS',
  'NEEDVALIDATION' = 'NEEDVALIDATION',
  'TOCOMPLETE' = 'TOCOMPLETE',
}

export interface State {
  _id: string;
  name: StateName;
}
