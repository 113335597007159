import { Injectable, Optional } from '@angular/core';
import { App } from "@capacitor/app";
import { IonRouterOutlet, Platform } from "@ionic/angular/standalone";
import { ActivatedRoute, Router } from "@angular/router";
import { HumanPath } from "../../dictionary";

@Injectable({
	providedIn: 'root'
})
export class BackButtonService {

	constructor(
		private platform: Platform,
		private route: ActivatedRoute,
		private router: Router,
		@Optional() private routerOutlet?: IonRouterOutlet
	) {
	}

	initBackButton() {
		this.platform.backButton.subscribeWithPriority(-1, () => {
			if (!this.routerOutlet.canGoBack()) {
				// if not on main view, redirect to it
				if (this.route.snapshot.url.pop().path != HumanPath.catalogs)
					this.router.navigate([HumanPath.catalogs]);
				// else exit the app
				else
					App.exitApp();
			}
		});
	}

}
