import { Injectable } from '@angular/core';
import { addIcons } from 'ionicons';
import {
	cartOutline, heartOutline, close, heart, storefront, apps, chevronForwardOutline, chevronBackOutline, car, basket, sparkles, diamond, hammer, wine, paw, leaf, basketballOutline, shirt, desktop, glasses, fastFood, cart, balloon, home, search, star, starOutline, compassOutline, arrowBackOutline, newspaperOutline, storefrontOutline, appsOutline, newspaper, documentAttachOutline, logoFacebook, informationCircleOutline,
} from 'ionicons/icons';

@Injectable({
	providedIn: 'root',
})
export class IconsService {
	/**
	 * List here all the Ionicons used in the application in order to use them in ion-icon
	 */
	initIcons() {
		addIcons({
			cartOutline,
			heartOutline,
			heart,
			storefront,
			apps,
			chevronForwardOutline,
			chevronBackOutline,
			car,
			basket,
			sparkles,
			diamond,
			hammer,
			wine,
			paw,
			leaf,
			basketballOutline,
			shirt,
			desktop,
			glasses,
			fastFood,
			cart,
			balloon,
			home,
			search,
			close,
			star,
			starOutline,
			compassOutline,
			arrowBackOutline,
			newspaperOutline,
			newspaper,
			storefrontOutline,
			appsOutline,
			documentAttachOutline,
			logoFacebook,
			informationCircleOutline
		});
	}
}
