import { Component, OnInit } from '@angular/core';
import { progressState } from "../../loading.state";
import { IonSpinner, ToastController } from "@ionic/angular/standalone";
import { JsonPipe } from "@angular/common";

@Component({
	selector: 'app-discret-progress',
	templateUrl: './discret-progress.component.html',
	styleUrls: ['./discret-progress.component.scss'],
	imports: [
		IonSpinner,
		JsonPipe
	],
	standalone: true
})
export class DiscretProgressComponent {

	progressState = progressState;

	constructor(private toastService: ToastController) {
	}

	async showMessage() {
		if (progressState.show && progressState.message) {
			const toast = await this.toastService.create({
				message: progressState.message,
				duration: 3000
			});
			toast.present();
		}
	}

}
