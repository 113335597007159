export enum CatalogFilterType {
	myCompanies = 'mesEnseignes',
	all = 'tous',
	// @ts-ignore
	company = 'enseigne',
	// @ts-ignore
	category = 'categorie',
	favorites = 'favoris',
}

export const NO_FILTER_ID = '_';
