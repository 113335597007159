import { ActivatedRouteSnapshot, Routes } from "@angular/router";
import { inject } from "@angular/core";
import { CatalogService } from "./services/api/catalog.service";
import { HumanPath } from "./dictionary";
import { routePath, routePathDeclaration, routeToCatalogList } from "./utils/route.utils";
import { MentionsLegalesComponent } from "./pages/legal/mentions-legales/mentions-legales.component";
import { ConditionsRodzaferComponent } from "./pages/legal/conditions-rodzafer/conditions-rodzafer.component";
import { ConditionsArtComponent } from "./pages/legal/conditions-art/conditions-art.component";

export const routes: Routes = [
  // catalog list with params
  {
    path: routePathDeclaration(HumanPath.catalogs, ":filter", ":filterId"),
    title: 'Catalogue, promotions, prospectus et bonnes affaires La Réunion - Rodzafer.re',

    loadComponent: () => import("./pages/catalog-list/catalog-list.component").then((m) => m.CatalogListComponent)
  },
  // catalog list
  {
    path: HumanPath.catalogs,
    redirectTo: routeToCatalogList(),
    pathMatch: "full"
  },
  {
    path: HumanPath.companies,
    title: 'Enseignes, promotions et catalogues de boutiques à La Réunion - Rodzafer.re',
    loadComponent: () => import("./pages/company-list/company-list.component").then((m) => m.CompanyListComponent)
  },
  // catalog detail
  {
    path: routePathDeclaration(HumanPath.catalog, ":id", ":page"),
    loadComponent: () => import("./pages/catalog-single/catalog-single.component").then((m) => m.CatalogSingleComponent),
    resolve: {
      catalog: (route: ActivatedRouteSnapshot) => {
        return inject(CatalogService).getOne(route.params["id"]);
      }
    },
  },
  {
    path: routePathDeclaration(HumanPath.catalog, ":id"),
    redirectTo: routePath([HumanPath.catalog, ":id", "1"]),
    pathMatch: "full"
  },
  // legales pages
  {
    path: "legal",
    children: [
      {path: "mentions-legales", component: MentionsLegalesComponent},
      {path: "conditions-rodzafer", component: ConditionsRodzaferComponent},
      {path: "conditions-art", component: ConditionsArtComponent}
    ]
  },
  {
    path: "**", redirectTo: routeToCatalogList(), pathMatch: "full"
  }
];
