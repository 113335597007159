import { Injectable } from '@angular/core';
import { StorageService } from "./storage.service";

const USER_PARAM_PREFIX = '_userparam_';

export enum UserParam {
	CURRENT_CITY = "CURRENT_CITY"
}

@Injectable({
	providedIn: 'root'
})
export class UserParamsService {

	// cache value
	private userParams: { [key: string]: any } = {};

	constructor(private storage: StorageService) {
	}

	async initUserParams() {
		await this.storage.initStorage();
	}

	private key(key: UserParam) {
		return USER_PARAM_PREFIX + key;
	}

	async setUserParam<T>(param: UserParam, value: T) {
		const key = this.key(param);
		this.userParams[key] = value;
		await this.storage.set<T>(key, value);
	}

	async getUserParam<T>(param: UserParam): Promise<T> {
		const key = this.key(param);
		if (!this.userParams[key])
			this.userParams[key] = await this.storage.get<T>(key);
		return this.userParams[key];
	}
}
