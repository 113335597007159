import { CatalogFilterType, NO_FILTER_ID } from "../models/catalog-filter.type";
import { HumanPath } from "../dictionary";
import { Catalog } from "../models/api/catalog.model";

export function routePath(parts: string[], startingSlash = true): string {
  let path = parts.join('/');
  if (startingSlash) path = '/' + path;
  return path;
}

export function routePathDeclaration(...parts: string[]): string {
  return routePath(parts, false);
}

export function routeToCatalogList(filter: CatalogFilterType = CatalogFilterType.all,
                                   filterId: string = NO_FILTER_ID) {
  return routePath([HumanPath.catalogs, filter, filterId])
}

export function routeToCatalog(catalog: Catalog) {
  return routePath([HumanPath.catalog, catalog._id]);
}

export function routeToCatalogId(id: string) {
  return routePath([HumanPath.catalog, id]);
}

export function routeToCatalogPage(id: string, page: number) {
  return routePath([HumanPath.catalog, id, `${page}`]);
}

export function routeToCompanyList() {
  return routePath([HumanPath.companies])
}

export const myCompaniesCatalogsRoute = routeToCatalogList(CatalogFilterType.myCompanies);

export function getDomain(url: string) {
  return url.split('//').pop().split('/').shift();
}

export function isRefererSameDomain() {
  const currentDomain = getDomain(window.location.href);
  const refererDomain = getDomain(window.document.referrer);
  return currentDomain == refererDomain;
}
